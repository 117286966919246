
import Vue, { PropType } from 'vue';

type ButtonColor = 'tt-primary' | 'tt-secondary';
type ButtonSize = 'large' | 'default';

export default Vue.extend({
  name: 'NoDataStub',

  props: {
    title: {
      type: String,
      default: '',
    },
    titleClass: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    subtitleClass: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    imgName: {
      type: String,
      default: 'empty_box.png',
    },
    imgWidth: {
      type: [Number, String],
      default: 'auto',
    },
    imgHeight: {
      type: [Number, String, null] as PropType<number | string | null>,
      default: null,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonTitle: {
      type: String,
      default: '',
    },
    buttonPrependIcon: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    buttonColor: {
      type: String as PropType<ButtonColor>,
      default: 'tt-primary',
    },
    buttonSize: {
      type: String as PropType<ButtonSize>,
      default: 'default',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    bottomImage: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    buttonHref: {
      type: String,
      default: '',
    },
  },
});
