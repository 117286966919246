
import { defineComponent, PropType } from 'vue';
import SectionTitle from '@/components/common/SectionTitle.vue';
import type { TranslateResult } from 'vue-i18n';
import IndicatorDynamics from './IndicatorDynamics.vue';
import CompetencyDynamics from './CompetencyDynamics.vue';

interface TabItem {
  name: string;
  label: string | TranslateResult;
  component: any;
}

export default defineComponent({
  name: 'DynamicsWidgetsTabbed',

  components: {
    SectionTitle,
    IndicatorDynamics,
    CompetencyDynamics,
  },

  props: {
    surveyIds: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    userId: {
      type: String,
      default: '',
    },
    isNps: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentTab: 0,
    };
  },

  computed: {
    tabs(): TabItem[] {
      return [
        {
          name: 'competencies',
          label: this.$t('analytics.feedback.competencies'),
          component: CompetencyDynamics,
        },
        {
          name: 'indicators',
          label: this.$t('analytics.feedback.indicators'),
          component: IndicatorDynamics,
        },
      ];
    },
  },
});
