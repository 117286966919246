
import { PropType, defineComponent } from 'vue';
import SelectTeams from '@/components/analytics/widgets/teams/SelectTeams.vue';
import { tsxassApi } from '@/services/api';
import {
  V1EntitiesAnalyticsFiltersListFilters,
  V1EntitiesAnalyticsFiltersListTeam,
  V1EntitiesAnalyticsFiltersListMetaValue,
} from '@/services/api/tsxass';
import errorHandler from '@/helpers/errorHandler';
import debounce from 'lodash/debounce';

type TeamMetaFilters = Record<string, V1EntitiesAnalyticsFiltersListMetaValue[]>;

const TEAMS_FILTER_NAME = 'teams';

type Chip = {
  filterName: string;
  filterId: number | string;
  label: string;
};

export default defineComponent({
  name: 'TeamMetaFilters',
  components: {
    SelectTeams,
  },
  props: {
    filters11111: {
      type: Object as PropType<TeamMetaFilters>,
      default: () => ({
        team: ['team 1.1.1'],
        age: ['1'],
        function: ['1'],
      }),
    },
  },
  data() {
    return {
      isMenuVisible: false,
      filters: [] as V1EntitiesAnalyticsFiltersListFilters[],
      teams: [] as V1EntitiesAnalyticsFiltersListTeam[],
      selectedFilters: {} as TeamMetaFilters,
      selectedTeams: [] as V1EntitiesAnalyticsFiltersListTeam[],
    };
  },
  computed: {
    selectedTeamIds() {
      return this.selectedTeams.map((team) => team.id);
    },
    allFilters() {
      return this.filters;
    },
    visibleFilters() {
      const length = this.$vuetify.breakpoint.smAndDown ? 2 : 3;
      return this.allFilters.slice(0, length);
    },
    visibleCols() {
      return 12 / (this.visibleFilters.length + 1);
    },
    menuFilters() {
      return this.allFilters.slice(this.visibleFilters.length);
    },
    chips() {
      const chips: Chip[] = this.selectedTeams.map((team) => ({
        filterName: TEAMS_FILTER_NAME,
        filterId: team.id,
        label: team.name,
      }));
      Object.keys(this.selectedFilters).forEach((filterName: string) => {
        const filterValues = this.selectedFilters[filterName] || [];
        filterValues.forEach((filter) => {
          chips.push({
            filterName,
            filterId: filter.id,
            label: filter.name,
          });
        });
      });
      return chips;
    },
  },
  created() {
    this.onFiltersApply = debounce(this.onFiltersApply, 500);
  },
  mounted() {
    this.loadMetafilters();
  },
  methods: {
    async loadMetafilters() {
      try {
        this.isLoading = true;
        const {
          data: { filters, teams },
        } = await tsxassApi.getV1AnalyticsFilters();
        this.filters = filters || [];
        this.teams = teams || [];
        this.onFiltersApply();
      } catch (err) {
        errorHandler(err);
      } finally {
        this.isLoading = false;
      }
    },
    applyTeam(teams: V1EntitiesAnalyticsFiltersListTeam[]) {
      this.selectedTeams = teams;
      this.onFiltersApply();
    },
    applyFilter(filterName: string, value: V1EntitiesAnalyticsFiltersListMetaValue[]) {
      this.selectedFilters = {
        ...this.selectedFilters,
        [filterName]: value,
      };
      this.onFiltersApply();
    },
    clearFilters() {
      this.selectedFilters = {};
      this.selectedTeams = [];
      this.onFiltersApply();
    },
    removeFilter(chip: Chip) {
      const { filterName } = chip;
      if (filterName === TEAMS_FILTER_NAME) {
        this.selectedTeams = this.selectedTeams.filter((filter) => filter.id !== chip.filterId);
      } else {
        this.selectedFilters = {
          ...this.selectedFilters,
          [filterName]: this.selectedFilters[filterName].filter((filter) => filter.id !== chip.filterId),
        };
      }
      this.onFiltersApply();
    },
    onFiltersApply() {
      const filters = {} as Record<string, number[]>;
      Object.entries(this.selectedFilters).forEach(([key, values]) => {
        if (values.length) {
          filters[key] = values.map((value) => value.id);
        }
      });
      this.$emit('apply-filters', {
        teams: this.selectedTeamIds,
        filters,
      });
    },
  },
});
