
import Vue from 'vue';
import errorHandler from '@/helpers/errorHandler';
import {
  V1EntitiesPaginateOutput,
  V1EntitiesAnalyticsManagementDashboardsAudience,
} from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import UsersTable from '@/components/analytics/feedback/UsersTable.vue';
import { DEFAULT_PAGINATION as GLOBAL_DEFAULT_PAGINATION } from '@/constants/pagination';
import debounce from 'lodash/debounce';

const DEFAULT_PAGINATION = { ...GLOBAL_DEFAULT_PAGINATION, limit: 20 };

export default Vue.extend({
  name: 'EmployeeDashboards',

  components: {
    UsersTable,
  },

  inject: ['RouteNames', 'metafilters'],

  data() {
    return {
      userSearchText: '',
      // Таблица дашбордов (пользователей)
      dashboards: {
        users: [] as V1EntitiesAnalyticsManagementDashboardsAudience[],
        pagination: { ...DEFAULT_PAGINATION } as V1EntitiesPaginateOutput,
      },
      usersLoading: false,
    };
  },

  computed: {
    tableItems() {
      return this.dashboards.users.map((user) => ({ user }));
    },
  },

  watch: {
    userSearchText: {
      immediate: true,
      handler() {
        this.searchUsers();
      },
    },
    metafilters() {
      this.searchUsers();
    },
  },

  created() {
    this.searchUsers = debounce(this.searchUsers, 300);
  },

  methods: {
    searchUsers() {
      this.resetUsersPagination();
      this.loadUsers(this.dashboards.pagination);
    },
    resetUsersPagination() {
      this.dashboards.pagination = { ...DEFAULT_PAGINATION };
    },
    async loadUsers({ limit, page }: { limit: number, page: number }) {
      try {
        this.usersLoading = true;

        const { data } = await tsxassApi.postV1AnalyticsManagementDashboards({
          ...this.metafilters,
          query: this.userSearchText,
          page,
          limit,
        });

        const updatedUsers = [...this.dashboards.users];
        updatedUsers.splice((page - 1) * limit, updatedUsers.length, ...data.audiences);

        this.dashboards = {
          users: updatedUsers,
          pagination: data.pagination,
        };
      } catch (err) {
        errorHandler(err);
      } finally {
        this.usersLoading = false;
      }
    },
  },
});
