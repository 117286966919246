
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';
import { LicenseType } from '@/helpers/constants';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import fileDownload from '@/helpers/fileDownload';

export default defineComponent({
  name: 'ExportCompetenciesToExcel',

  inject: ['metafilters'],

  props: {
    surveyId: {
      type: Number,
      default: -1,
    },
    userIds: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    disabled() {
      return this.userIds?.length === 0;
    },
    ...mapState('profile', {
      licenses: 'licenses',
    }),
    hasExportToExcelLicense(): boolean {
      return this.licenses.some((name: string) => name === LicenseType.TSXASS_360_EXCEL);
    },
  },

  methods: {
    async exportToExcel() {
      if (this.loading) {
        return;
      }
      try {
        this.loading = true;
        const { request } = await tsxassApi.postV1AnalyticsManagementDifferenceXlsxReport({
          surveyId: this.surveyId,
          userIds: this.userIds,
          ...this.metafilters,
        }, {
          responseType: 'blob',
        });
        if (request.response) {
          fileDownload(request.response, 'EmployeesCompetencies.xlsx');
        }
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
