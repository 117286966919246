
import { defineComponent, PropType } from 'vue';
import type { TranslateResult } from 'vue-i18n';
import LineChart from '@/components/analytics/charts/LineChart.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import { getLineChartColor } from '@/helpers/chartColors';
import {
  V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByIndicators,
  V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend,
} from '@/services/api/tsxass';
import { LineChartDataSet } from '@/components/analytics/charts/types';
import DynamicsDatasetsTable from './DynamicsDatasetsTable.vue';

export default defineComponent({
  name: 'CommonDynamicsWidget',

  components: {
    LineChart,
    TTLoader,
    DynamicsDatasetsTable,
  },

  props: {
    chartData: {
      type: [Object, null] as PropType<V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByIndicators>,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String as PropType<string | TranslateResult>,
      default: '',
    },
    selectedLegendItemIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    scale: {
      type: Object as PropType<{ minY: number; maxY: number } | null>,
      default: null,
    },
  },

  computed: {
    yStepSize() {
      return Math.max(this.maxY - this.minY, 100) / 4;
    },
    legendItemsMap(): Record<string, V1EntitiesAnalyticsFeedbackQuestionsDynamicsLegend> {
      return Object.fromEntries(this.chartData.legend.map((item) => [item.id, item]));
    },
    selectedLegendItemsString(): string {
      return this.selectedLegendItemIds.reduce(
        (res, id) => `${res}${res.length ? ';' : ''} ${this.legendItemsMap[id].name}`,
        '',
      );
    },
    bounds(): { minY: number; maxY: number } {
      if (this.scale) {
        return this.scale;
      }
      const allValues = this.chartData.dynamics.reduce(
        (res, item) => [...res, ...item.values.map((val) => val.value)],
        [] as number[],
      );

      return {
        minY: Math.min(...allValues),
        maxY: Math.max(...allValues),
      };
    },
    minY(): number {
      return this.bounds.minY;
    },
    maxY(): number {
      return this.bounds.maxY;
    },
    xLabels(): string[] {
      return this.chartData.dynamics.map((tick) => this.$dayjs(tick.name, 'YYYY-MM-DD').format('D MMMM YY'));
    },
    dataSets(): Array<LineChartDataSet> {
      return this.chartData.legend.map((legendItem, legendIndex) => {
        const values = this.chartData.dynamics.map((dynamicItem, index) => {
          const val = dynamicItem.values.find((value) => value.legendId === legendItem.id)?.value;
          return {
            x: index,
            y: typeof val === 'number' ? val : null,
          };
        });
        const color = getLineChartColor(legendIndex);

        return {
          id: legendItem.id,
          label: legendItem.name,
          data: values,
          borderColor: color,
          backgroundColor: color,
          pointBorderColor: 'white',
        };
      });
    },
    selectedDatasets(): Array<LineChartDataSet> {
      return this.dataSets.filter((dataset) => this.selectedLegendItemIds.includes(dataset.id!));
    },
    tableItems() {
      return this.selectedDatasets
        .filter((dataset) => dataset.data.length)
        .map((dataset) => {
          const value = dataset.data[dataset.data.length - 1]?.y;
          return {
            id: dataset.id,
            name: dataset.label,
            value: typeof value === 'number' ? +value.toFixed(1) : '—',
            color: dataset.backgroundColor,
          };
        });
    },
  },
});
