import crypto from 'crypto';

export default function createArchiveHash(surveyId: string | number, userIds: string[]) {
  // Сортируем массив userIds
  const sortedUserIds = userIds.sort();
  // Создаем строку для хеширования из surveyId и userIds
  const dataToHash = `${surveyId}:${sortedUserIds.join(',')}`;
  // Создаем хеш
  return crypto.createHash('sha256').update(dataToHash).digest('hex');
}
