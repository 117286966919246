
import { defineComponent } from 'vue';
import PdfPage from '@/components/analytics/pdf/PdfPage.vue';
import PdfAbstractRenderer from '@/components/analytics/pdf/renderers/PdfAbstractRenderer.vue';
import { getElementsOuterHeights } from '@/helpers/getElementOuterHeight';
import { V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswers } from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';

// Отступ снизу элементов (px)
const ANSWERS_LIST_MB = 8;

type PageElement = {
  type: 'answer' | 'question' | 'group';
  text: string;
  id: string;
};

export default defineComponent({
  name: 'PdfAnswersToGeneralQuestionsRenderer',

  components: {
    PdfPage,
  },

  extends: PdfAbstractRenderer,

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    surveyeeName: {
      type: String,
      default: '',
    },
    pageNumber: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      chartData: {
        questions: [],
        answers: [],
        groups: [],
      } as V1EntitiesAnalyticsFeedbackGeneralQuestionsAnswers,
      // Высота зоны контента страницы
      pageContentHeight: 0,
      // Значения высот элементов на странице
      elementsHeight: null as Record<string, number> | null,
    };
  },

  computed: {
    pageElements(): PageElement[] {
      const pageElements: PageElement[] = [];
      this.chartData.groups.forEach((g) => {
        pageElements.push({
          type: 'group',
          text: g.name,
          id: `${g.id}`,
        });
        let hasAnswers = false;
        this.chartData.questions.forEach((q) => {
          if (q.questionGroupId === g.id) {
            pageElements.push({
              type: 'question',
              text: q.name,
              id: `${g.id}-${q.id}`,
            });
            this.chartData.answers.forEach((a, idx) => {
              if (a.questionId === q.id) {
                hasAnswers = true;
                pageElements.push({
                  type: 'answer',
                  text: a.comment,
                  id: `${g.id}-${q.id}-${idx}`,
                });
              }
            });
            if (!hasAnswers) {
              pageElements.pop();
            }
          }
        });
        if (!hasAnswers) {
          pageElements.pop();
        }
      });
      return pageElements;
    },
    // Группы ответов, разделенные на страницы
    pages(): PageElement[][] {
      // Итоговый массив
      const pages = [] as PageElement[][];
      if (!this.elementsHeight) {
        pages.push(this.pageElements);
      } else {
        // страница итогового массива
        let currentPage: PageElement[];
        let freeHeight = 0;
        // перебираем все вопросы
        this.pageElements.forEach((element) => {
          // сколько места требуется очередному виджету
          const heightToAdd = (Number(this.elementsHeight?.[element.id]) || 0) - ANSWERS_LIST_MB;
          if (!currentPage || freeHeight < heightToAdd) {
            // место закончилось, начинаем новую страницу; вся высота страницы снова доступна
            freeHeight = this.pageContentHeight;
            currentPage = [];
            pages.push(currentPage);
          }
          currentPage.push(element);
          freeHeight -= heightToAdd;
        });
      }
      return pages.length ? pages : [[]];
    },
  },

  watch: {
    pages: {
      async handler() {
        // Рендерер готов тогда, когда список страниц рассчитался с учетом высоты элементов
        if (this.dataIsLoaded && this.elementsHeight) {
          await this.$nextTick();
          if (this.pageElements.length) {
            this.collectAndEmitImages();
          } else {
            this.skipCurrentRenderer();
          }
        }
      },
    },
  },

  async mounted() {
    this.pageContentHeight = this.$el.querySelector('.page-content')?.getBoundingClientRect().height || 0;
    if (!this.pageContentHeight) {
      this.$emit('error');
    }

    await this.loadChartData();
    await this.$nextTick();
    this.calculateElementsHeight();
  },

  methods: {
    async loadChartData() {
      try {
        const { data } = await tsxassApi.getV1AnalyticsFeedbackGeneralQuestionsAnswers(
          this.surveyId,
          this.userId || undefined,
        );
        this.chartData = {
          questions: data?.questions || [],
          answers: data?.answers || [],
          groups: data?.groups || [],
        };
      } catch (err) {
        this.$emit('error');
      } finally {
        this.dataIsLoaded = true;
      }
    },
    calculateElementsHeight() {
      this.elementsHeight = getElementsOuterHeights(this.$el as HTMLElement);
    },
  },
});
