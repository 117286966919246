
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import {
  type V1EntitiesYandexGptQuestionsQuestionItem,
} from '@/services/api/tsxass';
import GptNewIndicatorsDialog from './GptNewIndicatorsDialog.vue';

export default {
  name: 'GptCompetenciesBanner',
  components: {
    GptNewIndicatorsDialog,
  },
  props: {
    surveyId: {
      type: Number,
      default: undefined,
    },
    userId: {
      type: String,
      default: undefined,
    },
    factors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      questions: [] as V1EntitiesYandexGptQuestionsQuestionItem[],
      showIndicatorsDialog: false,
    };
  },
  computed: {
    currentFactorsNames() {
      return this.factors.map((factor) => factor.name);
    },
    newIndicators() {
      return this.questions.filter((question) => !this.currentFactorsNames.includes(question.name));
    },
    isHasNewIndicators() {
      return this.newIndicators.length > 0;
    },
  },
  mounted() {
    this.fetchQuestions();
  },
  methods: {
    async fetchQuestions() {
      try {
        this.loading = true;

        const { data: { questions } } = await tsxassApi
          .getV1YandexGptQuestions(this.surveyId, this.userId);
        this.questions = questions || [];
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
