
import Vue, { defineComponent } from 'vue';
import TTActionDialog from '@/components/ui/TTDialog/TTActionDialog.vue';
import TTLoader from '@/components/ui/TTLoader.vue';

type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => void;
};

export default defineComponent({
  name: 'GptNewIndicatorsDialog',

  components: {
    TTActionDialog,
    TTLoader,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    indicators: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      formData: { ...this.getInitialFormValue() },
    };
  },

  computed: {
    isDialogVisible: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
    form(): VForm {
      return this.$refs.form as VForm;
    },
    isSubmitDisabled() {
      return this.formData?.indicators.length === 0;
    },
  },

  watch: {
    async isDialogVisible(value) {
      if (!value) {
        this.resetForm();
      }
    },
  },

  methods: {
    getInitialFormValue() {
      return {
        indicators: [],
      };
    },
    async submitForm() {
      this.$emit('submit', this.formData.indicators);
    },
    resetForm() {
      this.form.resetValidation();
      this.$emit('cancel');
    },
    checkboxState(item) {
      return this.formData.indicators.find((indicator) => indicator.id === item.id);
    },
  },
});
