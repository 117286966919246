
import Vue from 'vue';
import GradeVisualizationWidget from '@/components/analytics/widgets/GradeVisualizationWidget.vue';
import TopResultsWidget from '@/components/analytics/widgets/TopResultsWidget.vue';
import BaseCompetenciesHeatMap from '@/components/analytics/widgets/BaseCompetenciesHeatMap.vue';
import NoAnalyticsTabDataStub from '@/components/analytics/widgets/NoAnalyticsTabDataStub.vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import { VRow } from 'vuetify/lib';

export default Vue.extend({
  name: 'TabBaseCompetencies',

  components: {
    VRow,
    GradeVisualizationWidget,
    TopResultsWidget,
    BaseCompetenciesHeatMap,
    NoAnalyticsTabDataStub,
    TTLoader,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      default: '',
    },
    taskProgress: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      isJoharyEmpty: true,
      isAverageCountEmpty: true,
      isLoading: true,
    };
  },
  mounted() {
    this.loadAverageRate();
  },
  methods: {
    async loadAverageRate() {
      try {
        this.isLoading = true;
        const { data } = await tsxassApi.getV1AnalyticsFeedbackAverageRate(
          this.surveyId,
          undefined,
          this.userId || undefined,
          false,
        );
        this.isAverageCountEmpty = !data?.count;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
});
