
import Vue, { PropType } from 'vue';
import SectionTitle from '@/components/common/SectionTitle.vue';
import BarChart from '@/components/analytics/charts/BarChart.vue';
import { SurveyStageKind } from '@/constants/surveyStage';
import { BarChartDataSet } from '@/components/analytics/charts/types';
import { V1EntitiesAnalyticsOverviewStartSurvey } from '@/services/api/tsxass';
import getPercentage from '@/helpers/getPercentage';

interface ChooseExpertsResponse {
  expertLimit: number;
  more: number;
  less: number;
}

interface ChartData {
  x: number;
  y: number;
  label: string;
}

const DEFAULT_EXPERT_LIMIT = 5;

export default Vue.extend({
  name: 'UsersStatistics',

  components: {
    SectionTitle,
    BarChart,
  },
  props: {
    stage: {
      type: String as PropType<SurveyStageKind>,
      default: '',
    },
    values: {
      type: Object as PropType<ChooseExpertsResponse | V1EntitiesAnalyticsOverviewStartSurvey>,
      default: () => ({}),
    },
  },
  data() {
    return {
      chartData: [] as ChartData[],
      description: {
        // eslint-disable-next-line
        [SurveyStageKind.CHOOSING_EXPERTS]: this.$t(
          'surveyCompletionStatistics.userStatistics.expertsSelectionStageDescription',
        ),
        [SurveyStageKind.PASSAGE]: this.$t('surveyCompletionStatistics.userStatistics.assessmentStageDescription'),
      },
      backgroundColor: {
        [SurveyStageKind.CHOOSING_EXPERTS]: ['#EF5350', '#6EBD53'],
        [SurveyStageKind.PASSAGE]: ['#EF5350', '#FFC700', '#6EBD53'],
      },
      dataMapper: {
        // eslint-disable-next-line
        [SurveyStageKind.CHOOSING_EXPERTS]: ({ less, more }: ChooseExpertsResponse) => [less, more],
        [SurveyStageKind.PASSAGE]: (values: V1EntitiesAnalyticsOverviewStartSurvey) => Object.values(values),
      },
    };
  },
  computed: {
    xLabels(): string[] {
      if (this.stage === SurveyStageKind.CHOOSING_EXPERTS) {
        const expertLimit = this.values?.expertLimit || DEFAULT_EXPERT_LIMIT;
        return [
          this.$tc('surveyCompletionStatistics.userStatistics.choosingExperts.less', expertLimit),
          this.$tc('surveyCompletionStatistics.userStatistics.choosingExperts.more', expertLimit),
        ];
      }
      return [
        this.$t('surveyCompletionStatistics.userStatistics.passage.pending'),
        this.$t('surveyCompletionStatistics.userStatistics.passage.running'),
        this.$t('surveyCompletionStatistics.userStatistics.passage.done'),
      ] as string[];
    },
    dataSets(): BarChartDataSet[] {
      return [
        {
          data: this.chartData,
          backgroundColor: this.backgroundColor[this.stage as SurveyStageKind],
        },
      ];
    },
    isKnownStage() {
      return (Object.values(SurveyStageKind) as string[]).includes(this.stage);
    },
  },
  watch: {
    values: {
      handler() {
        this.mapChartData();
      },
      immediate: true,
    },
  },
  methods: {
    mapChartData() {
      const chartValues = this.dataMapper[this.stage as SurveyStageKind](this.values);
      const total = chartValues.reduce((a: number, b: number) => a + b, 0);

      this.chartData = chartValues.map((item, index) => ({
        x: index,
        y: getPercentage(item, total) || 0,
        label: `${getPercentage(item, total) || 0}% (${item} ${this.$t('surveyCompletionStatistics.people')})`,
      }));
    },
    yScaleLabelsFormatter(val: number): string {
      return `${val || 0}%`;
    },
  },
});
