export enum SurveyStageKind {
  CHOOSING_EXPERTS = 'choosing_experts',
  REVIEW_EXPERTS = 'review_experts',
  FINISHED = 'finished',
  PASSAGE = 'passage',
  SOON = 'soon',
}

export enum SurveyStageType {
  NEXT = 'next',
  PREV = 'prev',
  CURRENT = 'current',
}
