
import { defineComponent, PropType } from 'vue';
import { tsxassApi } from '@/services/api';
import { V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions } from '@/services/api/tsxass';
import errorHandler from '@/helpers/errorHandler';
import NoDataStub from '@/components/common/NoDataStub.vue';
import CommonDynamicsWidget from './CommonDynamicsWidget.vue';

export default defineComponent({
  name: 'CompetencyDynamics',

  components: {
    CommonDynamicsWidget,
    NoDataStub,
  },

  props: {
    surveyIds: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    userId: {
      type: String,
      default: '',
    },
    isNps: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chartData: {
        legend: [],
        dynamics: [],
      } as V1EntitiesAnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions,
      loading: false,
      selectedLegendItemIds: [] as string[],
    };
  },

  computed: {
    scale() {
      return this.isNps ? { minY: -100, maxY: 100 } : null;
    },
    hasData() {
      return this.chartData.dynamics.length > 0;
    },
  },

  watch: {
    surveyIds: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.length) {
          this.loadChartData();
        }
      },
    },
    userId() {
      this.loadChartData();
    },
    chartData() {
      if (!this.chartData.dynamics.length) {
        return;
      }
      // Выбираем все компетенции
      this.selectedLegendItemIds = this.chartData.legend.map((item) => item.id);
    },
  },

  methods: {
    async loadChartData() {
      try {
        this.loading = true;
        const apiCall = this.isNps
          ? tsxassApi.postV1AnalyticsFeedbackNpsQuestionsDynamicsByCompetentions
          : tsxassApi.postV1AnalyticsFeedbackGeneralQuestionsDynamicsByCompetentions;
        const { data } = await apiCall({
          surveyIds: this.surveyIds,
          userId: this.userId || undefined,
        });
        this.chartData = {
          legend: data.legend || [],
          dynamics: data.dynamics || [],
        };
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
