
import Vue, { computed } from 'vue';
import { mapGetters, mapMutations, mapState } from 'vuex';
import PageTitle from '@/components/common/PageTitle.vue';
import SectionTitle from '@/components/common/SectionTitle.vue';
import TimeToEndOfStage from '@/components/assessmentChecklist/TimeToEndOfStage.vue';
import UsersStatistics from '@/components/statistics/UsersStatistics.vue';
import EmployeesChoosingExperts from '@/components/statistics/EmployeesChoosingExperts.vue';
import StageCard from '@/components/statistics/StageCard.vue';
import {
  SET_SURVEY_META,
  SET_SURVEY_META_LOADING,
  SET_SURVEY_META_REQUEST,
} from '@/plugins/vuex/modules/survey/mutationTypes';
import { tsxassApi } from '@/services/api';
import {
  V1EntitiesAnalyticsOverviewChooseExperts,
  V1EntitiesAnalyticsOverviewStartSurvey,
  V1EntitiesSurveysPublicShowStage,
  PostV1AnalyticsManagementResult,
} from '@/services/api/tsxass';
import errorHandler from '@/helpers/errorHandler';
import getPercentage from '@/helpers/getPercentage';
import { SurveyStageKind, SurveyStageType } from '@/constants/surveyStage';
import fileDownload from '@/helpers/fileDownload';
import { EngagementAbility, LicenseType } from '@/helpers/constants';
import BackButton from '@/layouts/app/BackButton.vue';
import TeamMetaFilters from '@/components/analytics/widgets/TeamMetaFilters.vue';

interface ChartData {
  [SurveyStageKind.CHOOSING_EXPERTS]?: V1EntitiesAnalyticsOverviewChooseExperts;
  [SurveyStageKind.PASSAGE]?: V1EntitiesAnalyticsOverviewStartSurvey;
}

interface Progress {
  count: number;
  percent: number;
  total: number;
}

interface ProgressData {
  [SurveyStageKind.CHOOSING_EXPERTS]?: Progress;
  [SurveyStageKind.PASSAGE]?: Progress;
}

const { VUE_APP_ENGAGEMENTADMIN_ADDRESS } = process.env;

export default Vue.extend({
  name: 'SurveyStatistics',

  components: {
    StageCard,
    PageTitle,
    SectionTitle,
    TimeToEndOfStage,
    UsersStatistics,
    EmployeesChoosingExperts,
    BackButton,
    TeamMetaFilters,
  },

  provide() {
    return {
      metafilters: computed(() => this.metafilters),
    };
  },

  inject: ['RouteNames'],

  data() {
    return {
      loading: false,
      xlsExportLoading: false,
      chartData: null as ChartData | null,
      progressData: {} as ProgressData,
      stageTitles: {
        [SurveyStageKind.CHOOSING_EXPERTS]: this.$t('surveyCompletionStatistics.expertsSelectionStageTitle'),
        [SurveyStageKind.PASSAGE]: this.$t('surveyCompletionStatistics.assessmentStageTitle'),
      } as Record<string, string>,
      selectedStage: null as V1EntitiesSurveysPublicShowStage | null,
      metafilters: null as PostV1AnalyticsManagementResult | null,
    };
  },

  computed: {
    ...mapState('survey', {
      surveyMeta: 'meta',
    }),
    ...mapState('profile', {
      licenses: 'licenses',
    }),
    ...mapGetters('survey', {
      currentSurveyStage: 'currentSurveyStage',
    }),
    ...mapGetters('engagementInfo', {
      can: 'can',
    }),
    toBack() {
      return new URL('/app/surveys', VUE_APP_ENGAGEMENTADMIN_ADDRESS).href;
    },
    exportToXLSIsAvailable() {
      return (
        this.selectedStage?.kind === SurveyStageKind.PASSAGE
        && this.licenses.includes(LicenseType.ENABLE_XLSX_STATISTICS_DOWNLOADING)
        && this.can(EngagementAbility.CAN_SEND_REMINDERS)
      );
    },
    surveyId(): number {
      return Number(this.$route.params.surveyId);
    },
    isTestingMode(): boolean {
      return 'testing' in (this.$route.query || {});
    },
    dateEnd(): string | null {
      return this.selectedStage ? this.selectedStage.dateEnd : null;
    },
    isCompleted(): boolean {
      return !!this.dateEnd && this.$dayjs(this.dateEnd).isBefore(this.$dayjs());
    },
    timeToEnd(): string {
      if (!this.dateEnd || this.isCompleted) {
        return '';
      }

      return this.$dayjs(this.dateEnd).fromNow(true);
    },
    stages(): any[] {
      return this.surveyMeta?.stages || [];
    },
    stageTitle(): string {
      return this.selectedStage ? this.stageTitles[this.selectedStage.kind] : '';
    },
  },

  watch: {
    metafilters() {
      this.loadChartData();
    },
  },

  async created() {
    if (!this.isTestingMode) {
      await this.loadSurveyMeta();
      await this.setCurrentStage();
    } else {
      this[SET_SURVEY_META]({
        meta: null,
        metaLoading: false,
        metaRequest: null,
      });
    }
  },

  methods: {
    ...mapMutations('survey', [SET_SURVEY_META, SET_SURVEY_META_LOADING, SET_SURVEY_META_REQUEST]),

    async exportXLS() {
      try {
        this.xlsExportLoading = true;
        const { request } = await tsxassApi.getV1AnalyticsSurveysSurveyIdStatisticsPassingExportXlsx(this.surveyId, {
          responseType: 'blob',
        });
        if (request.response) {
          fileDownload(request.response, 'SurveyStatistics.xlsx');
        }
      } catch (err) {
        errorHandler(err);
      } finally {
        this.xlsExportLoading = false;
      }
    },
    async loadSurveyMeta() {
      try {
        this[SET_SURVEY_META_LOADING](true);
        const response = tsxassApi.getV1SurveysSurveyId(this.surveyId);
        this[SET_SURVEY_META_REQUEST](response);

        const { data } = await response;
        this[SET_SURVEY_META](data);
      } catch (err) {
        this.$router.push({ name: this.RouteNames.R_APP_HOME });
      } finally {
        this[SET_SURVEY_META_LOADING](false);
      }
    },
    async loadChartData() {
      try {
        this.loading = true;
        const [choosingExperts, passage] = await Promise.all([
          this.loadChoosingExpertsChartData(),
          this.loadPassageChartData(),
        ]);

        this.chartData = {
          [SurveyStageKind.CHOOSING_EXPERTS]: choosingExperts,
          [SurveyStageKind.PASSAGE]: passage,
        };
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async loadChoosingExpertsChartData() {
      try {
        const { data } = await tsxassApi.postV1AnalyticsOverviewChooseExperts({
          surveyId: this.surveyId,
          considerRole: true,
          ...this.metafilters,
        });
        return data;
      } catch (err) {
        errorHandler(err);
        return undefined;
      }
    },
    async loadPassageChartData() {
      try {
        const { data } = await tsxassApi.postV1AnalyticsOverviewStartSurvey({
          surveyId: this.surveyId,
          considerRole: true,
          ...this.metafilters,
        });
        return data;
      } catch (err) {
        errorHandler(err);
        return undefined;
      }
    },
    setCurrentStage() {
      this.selectedStage = this.currentSurveyStage || this.stages[0];
    },
    selectStage(stage: V1EntitiesSurveysPublicShowStage) {
      if (stage.type === SurveyStageType.NEXT) return;
      this.selectedStage = stage;
    },
    getProgress(stage: V1EntitiesSurveysPublicShowStage) {
      const dummyProgress = {
        count: 0,
        percent: 0,
        total: 0,
      };
      if (!this.chartData) {
        return dummyProgress;
      }

      const chartData = this.chartData[stage.kind as SurveyStageKind];

      if (!chartData) {
        return dummyProgress;
      }

      const progressMapper: Record<SurveyStageKind, any> = {
        [SurveyStageKind.CHOOSING_EXPERTS]: (data: V1EntitiesAnalyticsOverviewChooseExperts) => ({
          count: data.more,
          total: data.more + data.less,
          percent: getPercentage(data.more, data.more + data.less),
        }),
        [SurveyStageKind.PASSAGE]: (data: V1EntitiesAnalyticsOverviewStartSurvey) => ({
          count: data.done,
          total: data.pending + data.running + data.done,
          percent: getPercentage(data.done, data.pending + data.running + data.done),
        }),
      };

      return progressMapper[stage.kind as SurveyStageKind](chartData || {});
    },
  },
});
