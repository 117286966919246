
import Vue from 'vue';
import { mapState } from 'vuex';
import { tsxassApi } from '@/services/api';
import * as reportServiceApi from '@/services/api/report-service';
import isValidEmail from '@/helpers/emailValidator';
import errorHandler from '@/helpers/errorHandler';
import getArchiveHash from '@/helpers/getArchiveHash';
import { ArchiveStatuses } from '@/constants/reportService';

type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => void;
};

export default Vue.extend({
  name: 'ExportDialog',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    surveyId: {
      type: Number,
      default: undefined,
    },
    userId: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'pdf',
    },
    fetch: {
      type: Function,
      default: (val: any) => val,
    },
    progress: {
      type: Object,
      default: () => ({ loaded: 0, total: 0 }),
    },
  },
  data() {
    return {
      loading: false,
      loadingUsers: false,
      formData: { ...this.getInitialFormValue() },
      isShowHedgehog: false,
      query: false,
      pdfArchiveUrl: '',
      isShowForcePdfButton: false,
      pdfUsers: null,
    };
  },

  computed: {
    ...mapState('engagementInfo', { currentUser: 'user' }),
    isDialogVisible: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
    validationRules() {
      return {
        notEmpty: [(v: string): boolean | string => !!v?.trim() || (this.$t('commonValidation.required') as string)],
      };
    },
    form(): VForm {
      return this.$refs.form as VForm;
    },
    isEmailValid(): boolean {
      return isValidEmail(this.formData.email);
    },
    dialogTitle(): string {
      if (this.type === 'excel') {
        return this.$t('exportDialog.titleExcel') as string;
      }
      return this.$t('exportDialog.titlePdf') as string;
    },
    computedUserId(): string {
      return this.userId || this.currentUser?.userId;
    },
    percentageValue() {
      return Math.round((this.progress.loaded * 100) / this.progress.total) || 0;
    },
    waitingHours() {
      if (this.type === 'excel') {
        return 2;
      }
      return 6;
    },
    pdfDoubleArchiveTexts() {
      return this.pdfArchiveUrl
        ? this.$t('exportDialog.arhiveExistAndDoneHint')
        : this.$t('exportDialog.arhiveExistAndRunningHint');
    },
  },

  watch: {
    async isDialogVisible(value) {
      if (!value) {
        this.resetForm();
      }
    },
    progress: {
      handler(newVal, oldVal) {
        if (newVal?.loaded > oldVal?.loaded) {
          this.query = false;
        }
      },
      deep: true,
    },
  },

  methods: {
    getInitialFormValue() {
      return {
        email: '',
      };
    },
    async runExportToPdf(force = false) {
      try {
        this.loadingUsers = true;
        this.query = true;
        const users = this.pdfUsers || await this.fetch();
        if (users.length === 0) return;
        this.pdfUsers = users;

        const userIds = users.map((user) => user.userId);
        const hashKey = getArchiveHash(this.surveyId, userIds);

        const params = {
          surveyId: this.surveyId,
          userId: this.computedUserId,
          email: this.formData.email,
          users: users.map((user) => ({
            userId: user.userId,
            userName: `${user.lastName} ${user.firstName}`,
          })),
          hashKey,
          force,
        };

        const { exist, status, archiveUrl } = await reportServiceApi.archiveCreate(params);

        // обрабатываем если существует такая выгрузка
        if (exist) {
          // если архив был успешно выгружен, отдаем ссылку и показываем кнопку принудительного запуска
          if (status === ArchiveStatuses.DONE) {
            this.pdfArchiveUrl = archiveUrl;
            this.isShowForcePdfButton = true;
          }
          // пока обработки проваленных выгрузок нет, поэтому просто выгружаем как обычно
          if (status === ArchiveStatuses.FAILED) {
            this.isShowHedgehog = true;
          }
          // говорим что выгрузка уже запущена и показываем кнопку принудительного запуска
          if ([ArchiveStatuses.INIT, ArchiveStatuses.PROCESSING, ArchiveStatuses.ARCHIVING].includes(status)) {
            this.isShowForcePdfButton = true;
          }
        } else {
          this.isShowHedgehog = true;
        }
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loadingUsers = false;
      }
    },
    async runExportToExcel() {
      try {
        await tsxassApi.postV1AnalyticsManagementXlsxReport({
          surveyId: this.surveyId,
          recipientEmail: this.formData.email,
        });
        this.isShowHedgehog = true;
      } catch (err: any) {
        errorHandler(err);
      }
    },
    async submitForm() {
      if (!this.isEmailValid) return;

      if (this.type === 'excel') {
        await this.runExportToExcel();
        return;
      }
      await this.runExportToPdf();
    },
    resetForm() {
      this.form?.resetValidation();
      this.formData = { ...this.getInitialFormValue() };
      this.isShowHedgehog = false;
      this.pdfArchiveUrl = '';
      this.isShowForcePdfButton = false;
      this.pdfUsers = null;
      this.$emit('cancel');
    },
    close() {
      this.resetForm();
      this.$emit('cancel');
    },
  },
});
