
import Vue from 'vue';
import { Location } from 'vue-router';
import { mapMutations, mapGetters, mapState } from 'vuex';
import {
  SET_SURVEY_META,
  SET_SURVEY_META_LOADING,
  SET_SURVEY_META_REQUEST,
} from '@/plugins/vuex/modules/survey/mutationTypes';
import { tsxassApi } from '@/services/api';
import { SurveyStageKind, SurveyStageType } from '@/constants/surveyStage';
import RouteNames from '@/plugins/vue-router/routeNames';
import { SurveySettingNames } from '@/constants/SurveySettings';

export default Vue.extend({
  name: 'SurveyRoot',

  inject: ['RouteNames'],

  computed: {
    ...mapState('survey', {
      surveyMeta: 'meta',
    }),
    ...mapGetters('survey', {
      getSurveySettingValue: 'getSurveySettingValue',
      currentDisplayedSurveyStage: 'currentDisplayedSurveyStage',
      currentSurveyStage: 'currentSurveyStage',
      surveyStageReviewExperts: 'surveyStageReviewExperts',
      surveyStagePassage: 'surveyStagePassage',
    }),
    isTestingMode(): boolean {
      return 'testing' in (this.$route.query || {});
    },
    surveyId(): number {
      return Number(this.$route.params.surveyId);
    },
    hasUseFavoriteFlag(): boolean {
      return this.getSurveySettingValue(SurveySettingNames.USE_FAVORITE) || false;
    },
    isBetweenReviewExpertsAndPassage(): boolean {
      const reviewInPrevState = this.surveyStageReviewExperts?.type === SurveyStageType.PREV;
      const passageInNextState = this.surveyStagePassage?.type === SurveyStageType.NEXT;
      return reviewInPrevState && passageInNextState;
    },
    // Текущий маршрут соответствует текущему этапу?
    currentRouteIsCorrectForCurrentStage(): boolean {
      if (this.isBetweenReviewExpertsAndPassage) {
        return true;
      }

      if (!this.currentDisplayedSurveyStage
        || this.surveyMeta?.status === SurveyStageKind.SOON
        || (this.$route?.meta?.surveyStageKind
          && this.$route.meta.surveyStageKind !== this.currentDisplayedSurveyStage.kind)) {
        return false;
      }

      const routeValidators: Record<string, () => boolean> = {
        [RouteNames.R_APP_SURVEY_ROOT]:
          () => false,
        [RouteNames.R_APP_ASSESSMENT_COUPLES]:
          () => !this.hasUseFavoriteFlag && this.surveyMeta.status !== 'finished',
        [RouteNames.R_APP_ASSESSMENT_COUPLES_FAVORITES]:
          () => this.hasUseFavoriteFlag && this.surveyMeta.status !== 'finished',
        [RouteNames.R_APP_ASSESSMENT_REVIEW_EXPERTS]:
          () => this.hasUseFavoriteFlag && this.surveyMeta.status !== 'finished',
      };

      const routeValidator = routeValidators?.[this.$route.name || ''];
      if (routeValidator) {
        return routeValidator();
      }

      return true;
    },
    // "Релевантный" маршрут для текущего этапа
    currentStageRoute(): Location {
      if (
        !this.currentDisplayedSurveyStage
          || this.surveyMeta.status === 'finished'
          || this.surveyMeta.status === SurveyStageKind.SOON) {
        return { name: this.RouteNames.R_APP_HOME };
      }

      if (this.hasUseFavoriteFlag && (this.currentDisplayedSurveyStage.kind === SurveyStageKind.PASSAGE
        || (this.surveyMeta.status !== 'finished' && !this.currentSurveyStage))) {
        return { name: this.RouteNames.R_APP_ASSESSMENT_COUPLES_FAVORITES };
      }

      if (this.currentDisplayedSurveyStage.kind === SurveyStageKind.CHOOSING_EXPERTS) {
        return { name: this.RouteNames.R_APP_ASSESSMENT_COUPLES_EXPERTS };
      }

      if (this.currentDisplayedSurveyStage.kind === SurveyStageKind.REVIEW_EXPERTS) {
        return { name: this.RouteNames.R_APP_ASSESSMENT_REVIEW_EXPERTS };
      }

      return { name: this.RouteNames.R_APP_ASSESSMENT_COUPLES };
    },
  },

  async created() {
    if (!this.isTestingMode) {
      await this.loadSurveyMeta();
      this.redirect();
    } else {
      this[SET_SURVEY_META]({
        meta: null,
        metaLoading: false,
        metaRequest: null,
      });
    }
  },

  methods: {
    ...mapMutations('survey', [SET_SURVEY_META, SET_SURVEY_META_LOADING, SET_SURVEY_META_REQUEST]),

    async loadSurveyMeta() {
      try {
        this[SET_SURVEY_META_LOADING](true);
        const request = tsxassApi.getV1SurveysSurveyId(this.surveyId);
        this[SET_SURVEY_META_REQUEST](request);

        const { data } = await request;
        this[SET_SURVEY_META](data);
      } catch (err) {
        this.$router.push({ name: this.RouteNames.R_APP_HOME });
        throw new Error();
      } finally {
        this[SET_SURVEY_META_LOADING](false);
      }
    },
    // Редирект на страницу текущего этапа
    redirect() {
      if (this.currentRouteIsCorrectForCurrentStage) {
        return;
      }

      this.$router.replace(this.currentStageRoute);
    },
  },
});
