
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { VIcon } from 'vuetify/lib';
import isEqual from 'lodash/isEqual';
import { DEFAULT_PAGINATION } from '@/constants/pagination';
import errorHandler from '@/helpers/errorHandler';
import UsersTable from '@/components/analytics/feedback/UsersTable.vue';
import {
  V1EntitiesSurveysPublicShowStage,
  V1EntitiesAnalyticsOverviewStartSurveyListAudience,
  V1EntitiesAnalyticsOverviewChooseExpertsListAudience,
} from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import TTSimpleCheckbox from '@/components/ui/TTSimpleCheckbox.vue';
import StatisticsStatusChip from '@/components/statistics/StatisticsStatusChip.vue';
import StatisticsAudienceChip from '@/components/statistics/StatisticsAudienceChip.vue';
import { RATING_STATUS_DATA } from '@/constants/userRatingStatuses';
import { AUDIENCE_AMOUNT_DATA } from '@/constants/audienceAmountStatuses';
import { EngagementAbility } from '@/helpers/constants';
import debounce from 'lodash/debounce';

type Audience =
  | V1EntitiesAnalyticsOverviewStartSurveyListAudience
  | V1EntitiesAnalyticsOverviewChooseExpertsListAudience;

interface TableItem {
  user: Audience;
  checked: Boolean;
}

interface TableHeader {
  [x: string]: any;
}

function getKeysAsBoolean(obj: TableHeader) {
  return Object.fromEntries(Object.keys(obj).map((key) => [key, false]));
}

const PAGE_SIZE = 10;

export default Vue.extend({
  name: 'EmployeesChoosingExperts',

  components: {
    UsersTable,
    TTSimpleCheckbox,
    StatisticsStatusChip,
    StatisticsAudienceChip,
    VIcon,
  },

  inject: ['metafilters'],

  props: {
    stage: {
      type: Object as PropType<V1EntitiesSurveysPublicShowStage>,
      required: true,
    },
    surveyId: {
      type: Number,
      required: true,
    },
    filterValues: {
      type: Object as PropType<typeof RATING_STATUS_DATA | typeof AUDIENCE_AMOUNT_DATA>,
      required: true,
    },
  },

  data() {
    return {
      audience: [] as Audience[],
      selectedByDefault: false,
      pagination: { ...DEFAULT_PAGINATION, limit: PAGE_SIZE },
      loading: false,
      checked: [] as string[],
      sorting: {
        orderBy: [],
        direction: [],
      },
      filter: getKeysAsBoolean(this.filterValues),
      userNameQuery: '',
      notificationAvailable: false,
      fetchId: 0,
    };
  },

  computed: {
    ...mapGetters('engagementInfo', {
      can: 'can',
    }),
    tableHeaders(): TableHeader[] {
      const headers = [
        {
          value: 'checked',
          sortable: false,
          width: '40px',
          align: 'center',
          class: 'checked',
          hide: this.isStageFinished || !this.isCanSendReminders,
        },
        {
          text: this.$t('surveyCompletionStatistics.userStatistics.employee'),
          value: 'user',
          width: this.isStagePassage ? '25%' : '34%',
          sortable: false,
        },
        {
          text: this.$t('surveyCompletionStatistics.userStatistics.status'),
          value: 'status',
          sortable: false,
          hide: !this.isStagePassage,
        },
        {
          text: this.$t('surveyCompletionStatistics.userStatistics.status'),
          value: 'amount',
          sortable: false,
          hide: this.isStagePassage,
        },
        {
          text: this.$t('surveyCompletionStatistics.userStatistics.rated'),
          value: 'total',
          sortable: false,
          width: '15%',
          hide: !this.isStagePassage,
        },
        {
          text: '%',
          value: 'volume',
          sortable: true,
          width: '10%',
          hide: !this.isStagePassage,
        },
        {
          text: this.$t('surveyCompletionStatistics.userStatistics.passage.rejected'),
          value: 'rejected',
          sortable: true,
          hide: !this.isStagePassage,
        },
        {
          text: this.$t('surveyCompletionStatistics.userStatistics.expertsChosen'),
          value: 'expertCount',
          sortable: false,
          width: '26%',
          hide: this.isStagePassage,
        },
      ];
      return headers.filter((header) => !header.hide);
    },
    tableItems(): TableItem[] {
      return (this.audience || []).map((user) => ({
        // @ts-ignore
        ...user.progress,
        user,
        checked: this.checked.includes(user.userId) !== this.selectedByDefault,
      }));
    },
    expertLimit(): number {
      // @ts-ignore
      return this.filterValues.expertLimit;
    },
    isStagePassage(): Boolean {
      return this.stage.kind === 'passage';
    },
    isStageFinished(): Boolean {
      const { dateEnd } = this.stage;
      const endDate = this.$dayjs.utc(dateEnd);
      return endDate.isSameOrBefore(this.$dayjs());
    },
    selectedCount(): Number {
      return this.selectedByDefault ? this.pagination.total - this.checked.length : this.checked.length;
    },
    isCanSendReminders(): Boolean {
      return this.can(EngagementAbility.CAN_SEND_REMINDERS);
    },
  },
  watch: {
    sorting(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.clearPagination();
        this.fetchAudiences();
      }
    },
    filterValues() {
      this.checked = [];
      this.filter = getKeysAsBoolean(this.filterValues);
    },
    userNameQuery(value) {
      if (value?.length > 2 || !value) {
        this.onMetafiltersUpdate();
      }
    },
    metafilters() {
      this.checked = [];
      this.filter = getKeysAsBoolean(this.filterValues);
      this.onMetafiltersUpdate();
    },
    stage() {
      this.clearPagination();
      this.fetchAudiences();
    },
  },
  created() {
    this.onMetafiltersUpdate = debounce(this.onMetafiltersUpdate, 300);
  },
  mounted() {
    this.fetchAudiences();
  },
  methods: {
    onMetafiltersUpdate() {
      this.clearPagination();
      this.fetchAudiences();
    },
    getFilterStatuses() {
      return Object.entries(this.filter)
        .map(([key, value]) => (value ? key : ''))
        .filter(Boolean);
    },
    moreOrLess(user: V1EntitiesAnalyticsOverviewChooseExpertsListAudience) {
      return user.expertCount < this.expertLimit ? 'less' : 'more';
    },
    async sendNotifications() {
      try {
        const params = {
          surveyId: this.surveyId,
          includeUserIds: !this.selectedByDefault && this.checked.length ? this.checked : undefined,
          excludeUserIds: this.selectedByDefault && this.checked.length ? this.checked : undefined,
          ...this.metafilters,
        };
        const apiCall = this.isStagePassage
          ? tsxassApi.postV1AnalyticsOverviewSendNotificationStartSurvey
          : tsxassApi.postV1AnalyticsOverviewSendNotificationChooseExperts;
        this.loading = true;
        await apiCall(params);
        this.clearPagination();
        this.fetchAudiences();
      } catch (e) {
        errorHandler(e);
      } finally {
        this.loading = false;
      }
    },
    clearPagination() {
      this.pagination = { ...DEFAULT_PAGINATION, limit: PAGE_SIZE };
    },
    onFilterToggle(filterName: string | number) {
      const currentValue = this.filter[filterName];
      this.filter = { ...this.filter, [filterName]: !currentValue };
      this.checked = [];
      this.clearPagination();
      this.fetchAudiences();
    },
    onSelectAll() {
      this.selectedByDefault = !this.selectedByDefault;
      this.checked = [];
    },
    onUserToggle(user: Audience) {
      const idx = this.checked.indexOf(user.userId);
      if (idx > -1) {
        this.checked = this.checked.filter((userId) => userId !== user.userId);
      } else {
        this.checked.push(user.userId);
      }
    },
    onPaginationUpdate(pagination: { page: number; limit: number }) {
      this.pagination = { ...pagination, total: this.pagination.total };
      this.fetchAudiences();
    },
    async fetchExpertsChoosinAudiences() {
      const withStatus = this.getFilterStatuses();
      const { data } = await tsxassApi.postV1AnalyticsOverviewChooseExpertsList(
        {
          surveyId: this.surveyId,
          page: this.pagination.page,
          limit: this.pagination.limit,
          withStatus: withStatus.length ? withStatus : undefined,
          ...this.metafilters,
          query: this.userNameQuery?.length > 2 ? this.userNameQuery : undefined,
        },
        this.getFilterStatuses(),
      );
      return data;
    },
    async fetchPassageAudiences() {
      const { direction, orderBy } = this.sorting;
      const withStatus = this.getFilterStatuses();
      const { data } = await tsxassApi.postV1AnalyticsOverviewStartSurveyList(
        {
          surveyId: this.surveyId,
          page: this.pagination.page,
          limit: this.pagination.limit,
          withStatus: withStatus.length ? withStatus : undefined,
          ...this.metafilters,
          query: this.userNameQuery?.length > 2 ? this.userNameQuery : undefined,
          orderBy: orderBy[0] === 'rejected' ? 'count_rejected' : orderBy[0],
          direction: direction[0] ? 'ASC' : 'DESC',
        },
        this.getFilterStatuses(),
      );
      return data;
    },
    async fetchAudiences() {
      const { page, limit } = this.pagination;
      this.fetchId += 1;
      const thisFetchId = this.fetchId;
      try {
        this.loading = true;
        const apiCall = this.isStagePassage ? this.fetchPassageAudiences : this.fetchExpertsChoosinAudiences;
        const data = await apiCall();
        if (thisFetchId === this.fetchId) {
          this.pagination = data.pagination;
          this.notificationAvailable = data.notificationAvailable;
          this.audience.splice((page - 1) * limit, this.audience.length, ...data.audiences);
        }
      } catch (e) {
        errorHandler(e);
      } finally {
        if (thisFetchId === this.fetchId) {
          this.loading = false;
        }
      }
    },
  },
});
